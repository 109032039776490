import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import SVGMonoIcon from 'components/icons/SVGMonoIcon';
import SVGGramIcon from 'components/icons/SVGGramIcon';
import { black, white } from 'shared/colors';
import { convertToVW } from 'shared/utils';
import { BREAKPOINTS } from 'shared/constants';

interface Props {
  location: { pathname: string };
}

const editorial = ({ location: { pathname } }: Props) => {
  return (
    <main className={css(styles.container)}>
      <SVGMonoIcon style={styles.svgIcon} />
      <SVGGramIcon style={styles.svgIcon} />
    </main>
  );
};

export default editorial;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: black,
    color: white,
    minHeight: '100vh',
    width: '100%',
    paddingTop: '120px',
    [BREAKPOINTS.TABLET]: {
      paddingTop: '90px',
    },
  },
  svgIcon: {
    margin: '2.5rem auto',
    margin: '0rem auto',
    transform: 'scale(0.8)',
    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem',
      transform: 'scale(0.6)',
    },
  },
});
